import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";

export const ADD_ARTIST = "artist/create";
export const EDIT_ARTIST = "artist/edit";
export const GET_ARTIST_LIST = "artist/list";
export const GET_ARTIST = "artist/get";
export const ADD_ARTIST_ERROR = "artist/error";
export const DELETE_ARTIST = "artist/delete";

export const addArtist = createAsyncThunk(
  ADD_ARTIST,
  async ({ first_name, last_name, mobile, country, email, commission }) => {
    try {
      const res = await axios.post("/add-artist", {
        first_name,
        last_name,
        mobile,
        country,
        email,
        commission,
      });
      return res.data;
    } catch (error) {
      if (error.response.data) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  }
);

export const editArtist = createAsyncThunk(
  EDIT_ARTIST,
  async ({
    id,
    value: { first_name, last_name, mobile, country, email, commission },
  }) => {
    try {
      const res = await axios.put("/edit-artist/" + id, {
        first_name,
        last_name,
        mobile,
        country,
        email,
        commission,
      });
      return res.data;
    } catch (error) {
      if (error.response.data) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  }
);

export const getArtistList = createAsyncThunk(
  GET_ARTIST_LIST,
  async (args = {}) => {
    try {
      const { page = 1, limit = null } = args;
      const res = await axios.get("/artists", {
        params: {
          page,
          limit,
        },
      });
      return res.data;
    } catch (error) {
      if (error.response.data) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  }
);

export const getArtist = createAsyncThunk(GET_ARTIST, async (args = {}) => {
  try {
    const { id } = args;
    const res = await axios.get("/artists/" + id);
    return res.data;
  } catch (error) {
    if (error.response.data) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
});

export const deleteArtist = createAsyncThunk(DELETE_ARTIST, async (id) => {
  try {
    const res = await axios.delete("/delete-artist/" + id);

    return res.data;
  } catch (error) {
    if (error.response.data) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
});
