import { createSlice } from "@reduxjs/toolkit";
import {
  addPayment,
  getPaymentList,
  editPayment,
  getPayment,
} from "../actions/PaymentAction";

// its a copy of merchandise so convert it into payment works on progress

export const PaymentSlice = createSlice({
  name: "payment",
  initialState: {
    paymentUpdated: false,
    paymentCreated: false,
    payments: [],

    payment: {
      client_name: "",
      amount: "",
      appointment_date: "",
      appointment_time: "",
    },
    pagination:{},
    addPaymentError: "",
    deletePaymentStatus: false,
    deletePaymentMessage: "",
  },
  reducers: {
    createPayment: (state, action) => {
      state.payment = action.payload;
    },
    clearDeleteMerchandiseState: (state) => {
      state.deletePaymentStatus = false;
      state.deletePaymentMessage = "";
    },
    clearCreatePayment: (state) => {
      state.payment = {
        client_name: "",
        amount: "",
        appointment_date: "",
        appointment_time: "",
      };
      state.paymentCreated = false;
      state.addPaymentError = "";
      state.createPaymentStatus = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addPayment.pending, (state) => {
      state.paymentCreated = false;
    });
    builder.addCase(addPayment.fulfilled, (state, action) => {
      
      state.paymentCreated = true;
      state.payment = action.payload;
    });
    builder.addCase(addPayment.rejected, (state, action) => {
      state.paymentCreated = false;
      state.addPaymentError = action.error.message;
    });

    builder.addCase(getPaymentList.fulfilled, (state, action) => {
      state.payments = action.payload.paymentLinks;
      state.pagination= action.payload.pagination

    });
    builder.addCase(getPayment.fulfilled, (state, action) => {
      state.payment = action.payload.data;
    });
    builder.addCase(editPayment.pending, (state) => {
      state.paymentUpdated = false;
    });
    builder.addCase(editPayment.fulfilled, (state, action) => {
      state.paymentUpdated = true;
      state.payment = action.payload;
    });
    builder.addCase(editPayment.rejected, (state, action) => {
      state.paymentUpdated = false;
      state.addPaymentError = action.error.message;
    });
  },
});

export const paymentCreatedSuccessFully = (state) =>
  state.payment.paymentCreated;

export const paymentUpdatedSuccessFully = (state) =>
  state.payment.paymentUpdated;

export const paymentCreatedError = (state) => state.payment.addPaymentError;

export const payment = (state) => state.payment.payment;

export const fetchPaymentList = (state) => state.payment.payments;
export const fetchPagination = (state) => state.payment.pagination;


export const {
  createPayment,
  clearDeletePaymentState,
  clearCreatePayment,
  fetchPayments,
} = PaymentSlice.actions;

export default PaymentSlice.reducer;
