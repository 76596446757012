import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../auth/authRoles";

const AddPayment = Loadable(lazy(() => import("./AddPayment")));
const PaymentList = Loadable(lazy(() => import("./PaymentList")));


const marchRoutes = [
  { path: "/payment/add", element: <AddPayment />, auth: authRoles.admin },
  { path: '/payment', element: <PaymentList />, auth: authRoles.admin },
  { path: '/payment/:id', element: <AddPayment />, auth: authRoles.admin },


];

export default marchRoutes;
