import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios.js';

// export const ADD_MERCHANDISE = 'merchandise/create';
// export const EDIT_MERCHANDISE = 'merchandise/edit';
export const GET_PAYMENT_REFUND = 'payment/refund';
export const getPaymentRefund = createAsyncThunk(GET_PAYMENT_REFUND, async (args = {}) => {
    try {
        const { id, remarks } = args;
        const res = await axios.post('/stripe/refund', {
            id,
            remarks,
        }
        );
        return res.data;
    } catch (error) {
        if (error.response.data) {
            throw new Error(error.response.data.message);
        }
        throw error;
    }
});