import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios.js';

export const ADD_READER = 'stripe/terminal/reader/create';
export const EDIT_READER = 'stripe/terminal/reader/edit';
export const GET_READER_LIST = 'stripe/terminal/reader/list';
export const GET_READER = 'stripe/terminal/reader/get';
export const ADD_READER_ERROR = 'stripe/terminal/reader/error';

export const addReader = createAsyncThunk(
    ADD_READER,
    async ({ registration_code, label, location }) => {
        try {
            const res = await axios.post('/reader', {
                registration_code,
                label,
                location,
            });
            return res.data;
        } catch (error) {
            if (error.response.data) {
                throw new Error(error.response.data.message);
            }
            throw error;
        }
    }
);

export const editReader = createAsyncThunk(
    EDIT_READER
    // async ({ id, value: { first_name, last_name, mobile, country, email, commission } }) => {
    //     try {
    //         const res = await axios.put('/edit-artist/' + id, {
    //             first_name,
    //             last_name,
    //             mobile,
    //             country,
    //             email,
    //             commission,
    //         });
    //         return res.data;
    //     } catch (error) {
    //         if (error.response.data) {
    //             throw new Error(error.response.data.message);
    //         }
    //         throw error;
    //     }
    // }
);

export const getReaderList = createAsyncThunk(GET_READER_LIST, async (args = {}) => {
    try {
        const { startAfter = null, limit = 5 } = args;
        const res = await axios.get('/list_readers', {
            params: {
                ...(startAfter && { startAfter }),
                limit,
            },
        });
        return res.data;
    } catch (error) {
        if (error.response.data) {
            throw new Error(error.response.data.message);
        }
        throw error;
    }
});

export const getReader = createAsyncThunk(GET_READER, async (args = {}) => {
    // try {
    //     const { id } = args;
    //     const res = await axios.get('/artists/' + id);
    //     return res.data;
    // } catch (error) {
    //     if (error.response.data) {
    //         throw new Error(error.response.data.message);
    //     }
    //     throw error;
    // }
});
