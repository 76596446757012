import { styled } from "@mui/material";

import { useLocation } from "react-router-dom";
import success from "../assests/successfully.jpg";
import fail from "../assests/failed.jpg";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",

  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
const StatusImage = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  height: "200px",
}));

const Wrapper = styled("div")(({ theme }) => ({
  height: "400px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));
const StatusPayment = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type"); 
  return (
    <Container>
      <Wrapper>
        <StatusImage>
          {type === "success" ? (
            <img
              src={success}
              style={{ width: "auto", height: "60vh" }}
              alt="Success"
            ></img>
          ) : (
            <img
              src={fail}
              style={{ width: "auto", height: "60vh" }}
              alt="Fail"
            ></img>
          )}
        </StatusImage>
      </Wrapper>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "90px" }}
      >
        {" "}
        {type === "success" ? (
          <h2>Your payment has been successfully done</h2>
        ) : (
          <h2>Your payment has been failed</h2>
        )}
      </div>
    </Container>
  );
};

export default StatusPayment;
