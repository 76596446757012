import { createSlice } from '@reduxjs/toolkit';
import {
    addLocation,
    editLocation,
    getLocation,
    getLocationList,
} from '../actions/StripeTerminalLocationAction';

export const locationSlice = createSlice({
    name: 'location',
    initialState: {
        locationUpdated: false,
        locationCreated: false,
        locations: [],
        location: {},
        addlocationError: '',
    },
    reducers: {
        createLocation: (state, action) => {
            state.location = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addLocation.pending, (state) => {
            state.locationCreated = false;
        });
        builder.addCase(addLocation.fulfilled, (state, action) => {
            state.locationCreated = true;
            state.location = action.payload;
        });
        builder.addCase(addLocation.rejected, (state, action) => {
            state.locationCreated = false;
            state.addlocationError = action.error.message;
        });
        builder.addCase(getLocationList.fulfilled, (state, action) => {
            state.locations = action.payload.data;
        });
        builder.addCase(getLocation.fulfilled, (state, action) => {
            state.location = action.payload.location;
        });
        builder.addCase(editLocation.fulfilled, (state, action) => {
            state.locationUpdated = true;
            state.location = action.payload;
        });
        builder.addCase(editLocation.rejected, (state, action) => {
            state.locationUpdated = false;
            state.addlocationError = action.error.message;
        });
    },
});

export const locationCreatedSuccessFully = (state) => state.location.locationCreated;

export const locationUpdatedSuccessFully = (state) => state.location.locationUpdated;

export const locationCreatedError = (state) => state.location.addlocationError;

export const location = (state) => state.location.location;

export const fetchlocationList = (state) => state.location.locations;

export const { createLocation, fetchLocations } = locationSlice.actions;

export default locationSlice.reducer;
