import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const TerminlaLocationList = Loadable(lazy(() => import('./terminal/location/list')));
const TerminlaReaderList = Loadable(lazy(() => import('./terminal/reader/list')));
const TerminlaReaderAdd = Loadable(lazy(() => import('./terminal/common/add')));
const Terminal = Loadable(lazy(() => import('./terminal/terminal')));

const stripeRoute = [
    { path: '/stripe/location/list', element: <TerminlaLocationList />, auth: authRoles.admin },
    { path: '/stripe/reader/list', element: <TerminlaReaderList />, auth: authRoles.admin },
    { path: '/stripe/reader/add', element: <TerminlaReaderAdd />, auth: authRoles.admin },
    { path: '/stripe/terminal/:artistId', element: <Terminal />, auth: authRoles.admin },
    {
        path: '/stripe/marchendise/pay/:merchandiseId',
        element: <Terminal />,
        auth: authRoles.admin,
    },
];

export default stripeRoute;
