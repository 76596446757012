import { combineReducers } from 'redux';
import artist from './ArtistReducer';
import location from './StripeTerminalLacationReducer';
import reader from './StripeTerminalReaderReducer';
import stripeAccount from './StripeReducer';
import stripeTerminal from './StripeTerminalReducer';
import merchandise from './MerchandiseReducer';
import order from './OrderReducers';
import payment from './PaymentReducers';

import paymentRefund from './StripePaymentRefundReducer';
const RootReducer = combineReducers({
    order,
    artist,
    location,
    reader,
    stripeAccount,
    stripeTerminal,
    merchandise,
    paymentRefund,
    payment,
});

export default RootReducer;
