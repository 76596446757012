import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AddArtist = Loadable(lazy(() => import('./AddArtist')));
const ArtistList = Loadable(lazy(() => import('./ArtistList')));

const artistRoute = [
    { path: '/artists/add', element: <AddArtist />, auth: authRoles.admin },
    { path: '/artists', element: <ArtistList />, auth: authRoles.admin },
    { path: '/artists/:id', element: <AddArtist />, auth: authRoles.admin },
];

export default artistRoute;
