import { createSlice } from "@reduxjs/toolkit";
import { getOrderList } from "../actions/OrderActions";
// import fetch from "cross-fetch";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    orderList: [],
    pagination:{},
  },
  reducers: {
    setOrderList: (state, action) => {
      state.orderList = action.payload.map((order) => ({
        id: order.id,
        TransactionId: order.chargeId,
        remark: order.remarks,
        paidOn: order.paidOn,
        paidAmount: order.amount,
        refundAmount: order.refundAmount,
        status: order.status,
        receiptUrl: order.receiptUrl,
        type: order.object,
      }));
      state.pagination = {
        total: action.payload.pagination.total,
        per_page: action.payload.pagination.per_page,
        current_page: action.payload.pagination.current_page,
        last_page: action.payload.pagination.last_page,
        from: action.payload.pagination.from,
        to: action.payload.pagination.to,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderList.fulfilled, (state, action) => {
      // Assuming action.payload.Orders contains the array of orders
      state.orderList = action.payload.orders.map((order) => ({
        id: order.id,
        TransactionId: order.chargeId,
        remark: order.remarks,
        paidOn: order.paidOn,
        paidAmount: order.amount,
        refundAmount: order.refundAmount,
        status: order.status,
        description: order.description,
        receiptUrl: order.receiptUrl,
        type: order.object,
      }));
      state.pagination = {
        total: action.payload.pagination.total,
        per_page: action.payload.pagination.per_page,
        current_page: action.payload.pagination.current_page,
        last_page: action.payload.pagination.last_page,
        from: action.payload.pagination.from,
        to: action.payload.pagination.to,
      };
    });
  },
});

export const fetchOrderList = (state) => state.order.orderList;
export const fetchPagination = (state) => state.order.pagination;
export const { setOrderList } = orderSlice.actions;

export default orderSlice.reducer;
