import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios.js';

const GET_TERMINAL_CONNECTION_TOKEN = 'stripe/terminal/connection/token';
const REGISTER_DEVICE = 'stripe/terminal/register/device';

export const createConnectionToken = createAsyncThunk(GET_TERMINAL_CONNECTION_TOKEN, async () => {
    try {
        const res = await axios.post('/connection_token');
        return res.data;
    } catch (error) {
        if (error.response.data) {
            throw new Error(error.response.data.message);
        }
        throw error;
    }
});

export const registerDevice = createAsyncThunk(REGISTER_DEVICE, async () => {
    try {
        const res = await axios.post('/connection_token');
        return res.data;
    } catch (error) {
        if (error.response.data) {
            throw new Error(error.response.data.message);
        }
        throw error;
    }
});
