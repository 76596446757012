import { createSlice } from '@reduxjs/toolkit';
import {
    addReader,
    editReader,
    getReader,
    getReaderList,
} from '../actions/StripeTerminalReaderAction';

export const readerSlice = createSlice({
    name: 'reader',
    initialState: {
        readerUpdated: false,
        readerCreated: false,
        readers: [],
        reader: {},
        addReaderError: '',
    },
    reducers: {
        createreader: (state, action) => {
            state.reader = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addReader.pending, (state) => {
            state.readerCreated = false;
        });
        builder.addCase(addReader.fulfilled, (state, action) => {
            state.readerCreated = true;
            state.reader = action.payload;
        });
        builder.addCase(addReader.rejected, (state, action) => {
            state.readerCreated = false;
            state.addReaderError = action.error.message;
        });
        builder.addCase(getReaderList.fulfilled, (state, action) => {
            state.readers = action.payload.data;
        });
        builder.addCase(getReader.fulfilled, (state, action) => {
            state.reader = action.payload.reader;
        });
        builder.addCase(editReader.fulfilled, (state, action) => {
            state.readerUpdated = true;
            state.reader = action.payload;
        });
        builder.addCase(editReader.rejected, (state, action) => {
            state.readerUpdated = false;
            state.addReaderError = action.error.message;
        });
    },
});

export const readerCreatedSuccessFully = (state) => state.reader.readerCreated;

export const readerUpdatedSuccessFully = (state) => state.reader.readerUpdated;

export const readerCreatedError = (state) => state.reader.addReaderError;

export const reader = (state) => state.reader.reader;

export const fetchReaderList = (state) => state.reader.readers;

export const { createreader } = readerSlice.actions;

export default readerSlice.reducer;
