import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASEURL;

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error || "Something went wrong!")
);

export default axiosInstance;
