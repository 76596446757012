import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios.js';

export const ADD_STRIPE_ACCOUNT = 'stripe/account/create';
export const STRIPE_PAYMENT_INTENT = 'stripe/payment/intent';
export const STRIPE_CAPTURE_PAYMENT = 'stripe/payment/capture';
export const STRIPE_GET_CHARGE = 'stripe/payment/charge';

export const addStripeAccount = createAsyncThunk(ADD_STRIPE_ACCOUNT, async ({ user_id }) => {
    try {
        const res = await axios.post('/stripe/create-account', {
            user_id,
        });
        return res.data;
    } catch (error) {
        if (error.response.data) {
            throw new Error(error.response.data.message);
        }
        throw error;
    }
});

export const createPaymentIntent = createAsyncThunk(
    STRIPE_PAYMENT_INTENT,
    async ({ artist_id, merchandise_id, amount, description }) => {
        try {
            const res = await axios.post('/create_payment_intent', {
                artist_id,
                merchandise_id,
                amount,
                description,
            });
            return res.data;
        } catch (error) {
            if (error.response.data) {
                throw new Error(error.response.data.message);
            }
            throw error;
        }
    }
);

export const capturePaymentIntent = createAsyncThunk(
    STRIPE_CAPTURE_PAYMENT,
    async ({ payment_intent_id }) => {
        try {
            const res = await axios.post('/capture_payment_intent', {
                payment_intent_id,
            });
            return res.data;
        } catch (error) {
            if (error.response.data) {
                throw new Error(error.response.data.message);
            }
            throw error;
        }
    }
);

export const getChargeDetail = createAsyncThunk(
    STRIPE_GET_CHARGE,
    async ({ payment_intent_id }) => {
        try {
            const res = await axios.get('/charge/' + payment_intent_id, {
                payment_intent_id,
            });
            return res.data;
        } catch (error) {
            if (error.response.data) {
                throw new Error(error.response.data.message);
            }
            throw error;
        }
    }
);
