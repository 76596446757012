import AuthGuard from "app/auth/AuthGuard";
import dashboardRoutes from "app/views/dashboard/DashboardRoutes";
import artistRoute from "app/views/artist/ArtistRoutes";
import NotFound from "app/views/sessions/NotFound";
import sessionRoutes from "app/views/sessions/SessionRoutes";
import { Navigate } from "react-router-dom";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import stripeRoute from "./views/stripe/StripeRoutes";
import merchandiseRoute from "./views/merchandise/MerchandiseRoutes";
import paymentRoute from "./views/payment/PaymentRoutes";

import orders from "./views/order/OrderRoutes";
import StatusPayment from "./StatusPayment";

const routes = [
  {
    element: (
      <AuthGuard>
        <ToastContainer />
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...artistRoute,
      ...stripeRoute,
      ...merchandiseRoute,
      ...orders,
      ...paymentRoute,
    ],
  },
  ...sessionRoutes,
  { path: "/", element: <Navigate to="dashboard/default" /> },
  { path: "*", element: <NotFound /> },
  { path: "/payment/status", element: <StatusPayment /> },

];

export default routes;
