import { createSlice } from '@reduxjs/toolkit';
import {
    addStripeAccount,
    capturePaymentIntent,
    createPaymentIntent,
    getChargeDetail,
} from '../actions/StripeAction';

export const stripeAccountSlice = createSlice({
    name: 'stripeAccount',
    initialState: {
        stripeAccountCreated: false,
        stripeAccount: '',
        stripeAccountError: '',
        paymentIntent: null,
        paymentIntentError: '',
        capturePayment: null,
        capturePaymentError: '',
        chargeDetail: {},
        chargeDetailError: '',
    },
    reducers: {
        createAccout: (state, action) => {
            state.stripeAccount = action.payload;
        },
        clearPaymentDetail: (state) => {
            state.paymentIntent = null;
            state.capturePayment = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addStripeAccount.pending, (state) => {
            state.stripeAccountCreated = false;
            state.stripeAccountError = '';
        });
        builder.addCase(addStripeAccount.fulfilled, (state, action) => {
            state.stripeAccountCreated = true;
            state.stripeAccount = action.payload;
            state.stripeAccountError = '';
        });
        builder.addCase(addStripeAccount.rejected, (state, action) => {
            state.stripeAccountCreated = false;
            state.stripeAccountError = action.error.message;
        });
        builder.addCase(createPaymentIntent.pending, (state) => {
            state.paymentIntent = null;
            state.paymentIntentError = '';
        });
        builder.addCase(createPaymentIntent.fulfilled, (state, action) => {
            state.paymentIntent = action.payload.data;
            state.paymentIntentError = '';
        });
        builder.addCase(createPaymentIntent.rejected, (state, action) => {
            state.paymentIntent = null;
            state.paymentIntentError = action.error.message;
        });
        builder.addCase(capturePaymentIntent.pending, (state) => {
            state.capturePayment = null;
            state.capturePaymentError = '';
        });
        builder.addCase(capturePaymentIntent.fulfilled, (state, action) => {
            state.capturePayment = action.payload.data;
            state.capturePaymentError = '';
        });
        builder.addCase(capturePaymentIntent.rejected, (state, action) => {
            state.capturePayment = null;
            state.capturePaymentError = action.error.message;
        });
        builder.addCase(getChargeDetail.pending, (state) => {
            state.chargeDetail = {};
            state.chargeDetailError = '';
        });
        builder.addCase(getChargeDetail.fulfilled, (state, action) => {
            state.chargeDetail = action.payload.data;
            state.chargeDetailError = '';
        });
        builder.addCase(getChargeDetail.rejected, (state, action) => {
            state.chargeDetail = {};
            state.chargeDetailError = action.error.message;
        });
    },
});

export const stripeAccountCreatedSuccessFully = (state) => state.stripeAccount.stripeAccountCreated;
export const stripeAccountCreatedError = (state) => state.stripeAccount.stripeAccountError;
export const stripeAccount = (state) => state.stripeAccount.stripeAccount;
export const paymentIntent = (state) => state.stripeAccount.paymentIntent;
export const paymentIntentError = (state) => state.stripeAccount.paymentIntentError;
export const capturePayment = (state) => state.stripeAccount.capturePayment;
export const capturePaymentError = (state) => state.stripeAccount.capturePaymentError;
export const chargeDetail = (state) => state.stripeAccount.chargeDetail;
export const chargeDetailError = (state) => state.stripeAccount.chargeDetailError;

export const { createAccout, clearPaymentDetail } = stripeAccountSlice.actions;

export default stripeAccountSlice.reducer;
