import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios.js';

export const ADD_LOCATION = 'stripe/terminal/location/create';
export const EDIT_LOCATION = 'stripe/terminal/location/edit';
export const GET_LOCATION_LIST = 'stripe/terminal/location/list';
export const GET_LOCATION = 'stripe/terminal/location/get';
export const ADD_LOCATION_ERROR = 'stripe/terminal/location/error';

export const addLocation = createAsyncThunk(
    ADD_LOCATION
    // async ({ first_name, last_name, mobile, country, email, commission }) => {
    //     try {
    //         const res = await axios.post('/add-artist', {
    //             first_name,
    //             last_name,
    //             mobile,
    //             country,
    //             email,
    //             commission,
    //         });
    //         return res.data;
    //     } catch (error) {
    //         if (error.response.data) {
    //             throw new Error(error.response.data.message);
    //         }
    //         throw error;
    //     }
    // }
);

export const editLocation = createAsyncThunk(
    EDIT_LOCATION
    // async ({ id, value: { first_name, last_name, mobile, country, email, commission } }) => {
    //     try {
    //         const res = await axios.put('/edit-artist/' + id, {
    //             first_name,
    //             last_name,
    //             mobile,
    //             country,
    //             email,
    //             commission,
    //         });
    //         return res.data;
    //     } catch (error) {
    //         if (error.response.data) {
    //             throw new Error(error.response.data.message);
    //         }
    //         throw error;
    //     }
    // }
);

export const getLocationList = createAsyncThunk(GET_LOCATION_LIST, async (args = {}) => {
    try {
        const { startAfter = null, limit = 5 } = args;
        const res = await axios.get('/list_locations', {
            params: {
                ...(startAfter && { startAfter }),
                limit,
            },
        });
        return res.data;
    } catch (error) {
        if (error.response.data) {
            throw new Error(error.response.data.message);
        }
        throw error;
    }
});

export const getLocation = createAsyncThunk(GET_LOCATION, async (args = {}) => {
    // try {
    //     const { id } = args;
    //     const res = await axios.get('/artists/' + id);
    //     return res.data;
    // } catch (error) {
    //     if (error.response.data) {
    //         throw new Error(error.response.data.message);
    //     }
    //     throw error;
    // }
});
