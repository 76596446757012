import { createSlice } from "@reduxjs/toolkit";
import { getPaymentRefund } from "../actions/StripePaymentRefundAction";

export const paymentSlice = createSlice({
    name: 'paymentRefund',
    initialState: {
        data: null,
        status: '',
        message: '',
    },
    reducers: {
        clearPaymentRefundState: (state) => {
            state.data = null;
            state.status = false;
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPaymentRefund.pending, (state) => {
            // Handle pending state if needed
            state.status = false;
        });
        builder.addCase(getPaymentRefund.fulfilled, (state, action) => {
            // Handle fulfilled state based on response structure
            state.data = action.payload.data || null;
            state.status = action.payload.status || false;
            state.message = action.payload.message || '';
        });
        builder.addCase(getPaymentRefund.rejected, (state, action) => {
            // Handle rejected state if needed
            state.status = false;
            state.message = action.error.message || 'Request failed';
        });
    },
});

// Selectors
export const selectPaymentData = (state) => state.paymentRefund?.data;
export const selectPaymentStatus = (state) => state.paymentRefund?.status;
export const selectPaymentMessage = (state) => state.paymentRefund?.message;
export const { clearPaymentRefundState } = paymentSlice.actions;

export default paymentSlice.reducer;