import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios.js';

export const ADD_MERCHANDISE = 'merchandise/create';
export const EDIT_MERCHANDISE = 'merchandise/edit';
export const GET_MERCHANDISE_LIST = 'merchandise/list';
export const GET_MERCHANDISE = 'merchandise/get';
export const ADD_MERCHANDISE_ERROR = 'merchandise/error';
export const DELETE_MERCHANDISE = 'merchandise/delete';

export const addMerchandise = createAsyncThunk(
    ADD_MERCHANDISE,
    async ({ description, price, artist_id, commission }) => {
        try {
            const res = await axios.post('/add-merchandise', {
                description,
                price,
                artist_id,
                commission,
            });
            return res.data;
        } catch (error) {
            if (error.response.data) {
                throw new Error(error.response.data.message);
            }
            throw error;
        }
    }
);

export const editMerchandise = createAsyncThunk(
    EDIT_MERCHANDISE,
    async ({ id, value: { description, price, artist_id, commission } }) => {
        try {
            const res = await axios.put('/edit-merchandise/' + id, {
                description,
                price,
                artist_id,
                commission,
            });
            return res.data;
        } catch (error) {
            if (error.response.data) {
                throw new Error(error.response.data.message);
            }
            throw error;
        }
    }
);

export const getMerchandiseList = createAsyncThunk(GET_MERCHANDISE_LIST, async (args = {}) => {
    try {
        const { page = 1, limit = null } = args;
        const res = await axios.get('/merchandise', {
            params: {
                page,
                limit,
            },
        });
        return res.data;
    } catch (error) {
        if (error.response.data) {
            throw new Error(error.response.data.message);
        }
        throw error;
    }
});

export const getMerchandise = createAsyncThunk(GET_MERCHANDISE, async (args = {}) => {
    try {
        const { id } = args;
        const res = await axios.get('/merchandise/' + id);
        return res.data;
    } catch (error) {
        if (error.response.data) {
            throw new Error(error.response.data.message);
        }
        throw error;
    }
});

export const deleteMerchandise = createAsyncThunk(DELETE_MERCHANDISE, async (id) => {
    try {
      const res = await axios.delete("/delete-merchandise/" + id);
      return res.data;
    } catch (error) {
      if (error.response.data) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  });