import { LoadingButton } from "@mui/lab";
import { Card, Grid, TextField } from "@mui/material";
import { Box, styled } from "@mui/system";
import useAuth from "app/hooks/useAuth";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

const FlexBox = styled(Box)(() => ({ display: "flex", alignItems: "center" }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: "center" }));

const ContentBox = styled(Box)(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0.01)",
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: "#1A2038",
  minHeight: "100% !important",
  "& .card": {
    maxWidth: 800,
    minHeight: 400,
    margin: "1rem",
    display: "flex",
    borderRadius: 12,
    alignItems: "center",
  },
}));

// inital login credentials
const initialValues = {
  email: "",
};

// form field validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid Email address")
    .required("Email is required!"),
});

const ArtistLogin = () => {
  const [loading, setLoading] = useState(false);
  const [alertValue, setAlertValue] = useState("");
  const [status, setStatus] = useState();

  const { artistLogin } = useAuth();

  const handleFormSubmit = async (values) => {
    console.log("mmmm", values);
    setLoading(true);
    try {
      await artistLogin(values.email, setAlertValue, setStatus);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (alertValue !== "") {
      const timerId = setTimeout(() => {
        setAlertValue("");
      }, 5000);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [alertValue]);

  return (
    <>
      <JWTRoot>
        <Card className="card">
          <Grid container>
            <Grid item sm={6} xs={12}>
              <JustifyBox p={4} height="100%" sx={{ minWidth: 320 }}>
                <img
                  src="/assets/images/illustrations/dreamer.svg"
                  width="100%"
                  alt=""
                />
              </JustifyBox>
            </Grid>

            <Grid item sm={6} xs={12}>
              <ContentBox>
                <Formik
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        fullWidth
                        size="small"
                        type="email"
                        name="email"
                        label="Email"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={handleChange}
                        helperText={touched.email && errors.email}
                        error={Boolean(errors.email && touched.email)}
                      />
                      {alertValue !== "" && (
                        <p
                          style={{
                            color: status ? "green" : "red",
                            marginBottom: "1rem",
                          }}
                        >
                          {alertValue}
                        </p>
                      )}

                      <LoadingButton
                        type="submit"
                        color="primary"
                        loading={loading}
                        variant="contained"
                        sx={{ mt: 2 }}
                      >
                        Submit
                      </LoadingButton>
                    </form>
                  )}
                </Formik>
              </ContentBox>
            </Grid>
          </Grid>
        </Card>
      </JWTRoot>
    </>
  );
};

export default ArtistLogin;
