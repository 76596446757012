import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios.js';
import { MatxLoading } from 'app/components';

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
};

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }

    const decodedToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload;

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            };
        }
        case 'LOGIN': {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user,
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        }
        case 'REGISTER': {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);


  const login = async (email, password) => {
    const response = await axios.post(`/login-auth`, {
      email,
      password,
    });
    const { api_token, user } = response.data;
    localStorage.setItem("role", response.data.user?.role);

    setSession(api_token);

    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };
  // const artistLogin = async (email, setAlertValue, setStatus) => {
  //   const response = await axios.post(`/connect-artist`, {
  //     email,
  //   });
  //   const { data, user } = response;
  //   setAlertValue(data.message);
  //   setStatus(data.status);
  //   window.location.reload();

  //   dispatch({
  //     type: "ARTIST-LOGIN",
  //     payload: {
  //       user,
  //     },
  //   });
  // };
  const artistLogin = async (email, setAlertValue, setStatus, dispatch) => {
    try {
      const response = await axios.post(`/connect-artist`, {
        email,
      });
      const { data, user } = response;

      setAlertValue(data.message);
      setStatus(data.status);

      dispatch({
        type: "ARTIST-LOGIN",
        payload: {
          user,
        },
      });
    } catch (error) {
      // Handle errors here
      console.error("Error in artistLogin:", error);
    }
  };

  const logout = () => {
    setSession(null);
    localStorage.removeItem("role");
    dispatch({ type: "LOGOUT" });
  };



    useEffect(() => {
        (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken);
                    const response = await axios.get('/me');
                    const { user } = response.data;

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    });
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        })();
    }, []);

    if (!state.isInitialised) {
        return <MatxLoading />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                artistLogin,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
