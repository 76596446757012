import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AddMerchandise = Loadable(lazy(() => import('./AddMerchandise')));
const MerchandiseList = Loadable(lazy(() => import('./MerchandiseList')));

const marchRoutes = [
    { path: '/merchandise/add', element: <AddMerchandise />, auth: authRoles.admin },
    { path: '/merchandise', element: <MerchandiseList />, auth: authRoles.admin },
    { path: '/merchandise/:id', element: <AddMerchandise />, auth: authRoles.admin },
];

export default marchRoutes;
