import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";
import { toast } from "react-toastify";

import { values } from "lodash";

export const ADD_PAYMENT = "payment/create";
export const EDIT_PAYMENT = "payment/edit";
export const GET_PAYMENT_LIST = "payment/list";
export const GET_PAYMENT = "payment/get";
export const DISABLE_PAYMENT = "payment/put";


// it a copy of merchandise  convert it into payment Action
// work on progress

export const addPayment = createAsyncThunk(
  ADD_PAYMENT,
  async ({
    client_name,
    amount,
    artist,
    appointment_date,
    appointment_time,
  }) => {
    try {
      const res = await axios.post("/payment-link", {
        client_name,
        amount,
        artist,
        appointment_date,
        appointment_time,
      });
      return res.data;
    } catch (error) {
      if (error.response.data) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  }
);

export const editPayment = createAsyncThunk(
  EDIT_PAYMENT,
  async ({
    client_name,
    amount,
    artist,
    appointment_date,
    appointment_time,
    id,
  }) => {
    try {
      const res = await axios.put("/payment-link/" + id, {
        client_name,
        amount,
        artist,
        appointment_date,
        appointment_time,
      });
      return res.data;
    } catch (error) {
      if (error.response.data) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  }
);

export const getPaymentList = createAsyncThunk(
  GET_PAYMENT_LIST,
  async (args = {}) => {
    try {
      const { page = 1, limit = null } = args;
      const res = await axios.get("/payment-link", {
        params: {
          page,
          limit,
        },
      });
      return res.data;
    } catch (error) {
      if (error.response.data) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  }
);

export const getPayment = createAsyncThunk(GET_PAYMENT, async (args = {}) => {
  try {
    const { id } = args;
    const res = await axios.get("/payment-link/" + id);
    return res.data;
  } catch (error) {
    if (error.response.data) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
});


export const disablePayment = createAsyncThunk(DISABLE_PAYMENT, async (id) => {
  try {
    const res = await axios.put("/payment-link/" + id, { status: "inactive" });
    if (res.data.status !== "success") {
      toast.error(res.data.message);
    }
    return res.data;
  } catch (error) {
    if (error.response.data) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
});