import { createSlice } from '@reduxjs/toolkit';
import {
    addMerchandise,
    editMerchandise,
    getMerchandise,
    getMerchandiseList,
    deleteMerchandise,
} from '../actions/MerchandiseAction';

export const merchandiseSlice = createSlice({
    name: 'merchandise',
    initialState: {
        merchandiseUpdated: false,
        merchandiseCreated: false,
        merchandises: {},
        merchandise: {
            description: '',
            price: '',
            commission: '',
        },
        addMerchandiseError: '',
        deleteMerchandiseStatus: false,
        deleteMerchandiseMessage: '',
    },
    reducers: {
        createMerchandise: (state, action) => {
            state.merchandise = action.payload;
        },
        clearDeleteMerchandiseState: (state) => {
            state.deleteMerchandiseStatus = false;
            state.deleteMerchandiseMessage = '';
        },
        clearCreateMerchandise: (state) => {
            state.merchandise = {
              description: '',
              price: '',
              commission: '',
            };
            state.merchandiseCreated = false;
            state.addMerchandiseError = '';
            state.createMerchandiseStatus = false;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(addMerchandise.pending, (state) => {
            state.merchandiseCreated = false;
        });
        builder.addCase(addMerchandise.fulfilled, (state, action) => {
            state.merchandiseCreated = true;
            state.merchandise = action.payload;
        });
        builder.addCase(addMerchandise.rejected, (state, action) => {
            state.merchandiseCreated = false;
            state.addMerchandiseError = action.error.message;
        });
        builder.addCase(getMerchandiseList.fulfilled, (state, action) => {
            state.merchandises = action.payload.merchandises;
        });
        builder.addCase(getMerchandise.fulfilled, (state, action) => {
            state.merchandise = action.payload.merchandise;
        });
        builder.addCase(editMerchandise.pending, (state) => {
            state.merchandiseUpdated = false;
        });
        builder.addCase(editMerchandise.fulfilled, (state, action) => {
            state.merchandiseUpdated = true;
            state.merchandise = action.payload;
        });
        builder.addCase(editMerchandise.rejected, (state, action) => {
            state.merchandiseUpdated = false;
            state.addMerchandiseError = action.error.message;
        });
        builder.addCase(deleteMerchandise.pending, (state) => {
            // Add any state changes while deleteMerchandise is pending (if needed)
            state.deleteMerchandiseStatus = false;
        });
        builder.addCase(deleteMerchandise.fulfilled, (state, action) => {
            // Modify state after successful deletion
            state.deleteMerchandiseStatus = action.payload.status;
            state.deleteMerchandiseMessage = action.payload.message;
        });
        builder.addCase(deleteMerchandise.rejected, (state, action) => {
            // Handle rejected case if needed (e.g., show error message)
            // Here, you might set an error message state if the delete request fails
            state.deleteMerchandiseStatus = false;
            state.deleteMerchandiseMessage = action.error.message;
        });
    },
});

export const merchandiseCreatedSuccessFully = (state) => state.merchandise.merchandiseCreated;

export const merchandiseUpdatedSuccessFully = (state) => state.merchandise.merchandiseUpdated;

export const merchandiseCreatedError = (state) => state.merchandise.addMerchandiseError;

export const merchandise = (state) => state.merchandise.merchandise;

export const merchandiseDeletedStatus = (state) => state.merchandise.deleteMerchandiseStatus;

export const merchandiseDeletedMessage = (state) => state.merchandise.deleteMerchandiseMessage;

export const fetchMerchandiseList = (state) => state.merchandise.merchandises;

export const { createMerchandise, clearDeleteMerchandiseState, clearCreateMerchandise,fetchMerchandises } = merchandiseSlice.actions;

export default merchandiseSlice.reducer;
