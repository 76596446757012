// import { ADD_ARTIST, ADD_ARTIST_ERROR, GET_ARTIST_LIST } from '../actions/ArtistAction';

import { createSlice } from '@reduxjs/toolkit';
import { addArtist, editArtist, getArtist, getArtistList } from '../actions/ArtistAction';

export const artistSlice = createSlice({
    name: 'artist',
    initialState: {
        artistUpdated: false,
        artistCreated: false,
        artists: {},
        artist: {
            first_name: '',
            last_name: '',
        },
        addArtistError: '',
    },
    reducers: {
        createArtist: (state, action) => {
            state.artist = action.payload;
        },
        clearArtist: (state) => {
            state.artist = {
                first_name: '',
                last_name: '',
            };
            state.artistCreated = false;
            state.artistUpdated = false;
            state.addArtistError = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addArtist.pending, (state) => {
            state.artistCreated = false;
        });
        builder.addCase(addArtist.fulfilled, (state, action) => {
            state.artistCreated = true;
            state.artist = action.payload;
        });
        builder.addCase(addArtist.rejected, (state, action) => {
            state.artistCreated = false;
            state.addArtistError = action.error.message;
        });
        builder.addCase(getArtistList.fulfilled, (state, action) => {
            state.artists = action.payload.artists;
        });
        builder.addCase(getArtist.fulfilled, (state, action) => {
            state.artist = action.payload.artist;
        });
        builder.addCase(editArtist.fulfilled, (state, action) => {
            state.artistUpdated = true;
            state.artist = action.payload;
        });
        builder.addCase(editArtist.rejected, (state, action) => {
            state.artistUpdated = false;
            state.addArtistError = action.error.message;
        });
    },
});

export const artistCreatedSuccessFully = (state) => state.artist.artistCreated;

export const artistUpdatedSuccessFully = (state) => state.artist.artistUpdated;

export const artistCreatedError = (state) => state.artist.addArtistError;

export const artist = (state) => state.artist.artist;

export const fetchArtistList = (state) => state.artist.artists;

export const { createArtist, clearArtist, fetchArtists } = artistSlice.actions;

export default artistSlice.reducer;
