import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios.js';

export const GET_ORDER_LIST = 'order/list';

export const getOrderList = createAsyncThunk(GET_ORDER_LIST, async (args = {}) => {
    try {
        const { page = 1, limit = null } = args;
        const res = await axios.get('/orders', {
            params: {
                page,
                limit,
            },
        });
        return res.data;
    } catch (error) {
        if (error.response.data) {
            throw new Error(error.response.data.message);
        }
        throw error;
    }
});

