import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../auth/authRoles";

const OrderList = Loadable(lazy(() => import("./OrderList")));

const orderRoutes = [
  { path: "/orders", element: <OrderList />, auth: authRoles.admin },
];

export default orderRoutes;
