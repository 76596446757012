import { createSlice } from '@reduxjs/toolkit';
import { createConnectionToken } from '../actions/StripeTerminalAction';

export const stripeTerminalSlice = createSlice({
    name: 'stripeTerminal',
    initialState: {
        connectionToken: null,
        connectionTokenError: '',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createConnectionToken.pending, (state) => {
            state.connectionToken = null;
        });
        builder.addCase(createConnectionToken.fulfilled, (state, action) => {
            state.connectionToken = action.payload;
        });
        builder.addCase(createConnectionToken.rejected, (state, action) => {
            state.connectionTokenError = action.error.message;
        });
    },
});

export const fetchConnectToken = (state) => state.stripeTerminal.connectionToken;

export const connectionTokenError = (state) => state.stripeTerminal.connectionTokenError;

export default stripeTerminalSlice.reducer;
